import React from 'react';
import { css } from "@emotion/core"
import { wrapper } from "../defaults/styles/elements"
import { color } from "../defaults/styles/theme"
import Link from "../link"
import HDM from '../defaults/assets/logos/hdm.svg';



const ThanksHDM = () => {
  return (
    <section
      css={css`
        background: ${color.main_light};
        padding-bottom: 5em;
      `}
    >
      <div css={[wrapper, css``]}>
        <Link
          to="https://www.hdm-stuttgart.de/"
          css={css`
            text-decoration: none;
            opacity: 0.5;
            display: block;
          `}
        >
          <div
            css={css`
              height: 8rem;
              margin-bottom: 1em;
              svg {
                width: 100%;
                height: 100%;
              }
            `}
          >
            <HDM />
          </div>
          <h2
            css={css`
              text-align: center;
              margin-bottom: 0;
              color: ${color.main_dark};
              font-size: 0.85em;
              letter-spacing: 0.02em;
              line-height: 1.3em;
              font-weight: 500;
            `}
          >
            Special Thanks to
            <br />
            Hochschule der Medien Stuttgart
            <br />
            for their continuous support
          </h2>
        </Link>
      </div>
    </section>
  )
}

export default ThanksHDM;