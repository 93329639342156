import React from "react";
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Marquee from "react-marquee-slider"
import { color } from '../defaults/styles/theme'
import { wrapper, pretitle } from '../defaults/styles/elements'

import { Adac, Agc, Arena2036, Basf, Bleistahl, Bosch, Bp, Covea, Daimler, Dpdhl, Dxc, Eberspaecher, Faurecia, Hyundai, Jardines, Linde, Motherson, Murata, Pnp, Porsche, Posteitaliane, Rrps, Sekisui, Tsystems, Tuev, Unistuttgart, Webasto, Wieland, Yanfeng, Zfwabco } from "../../components/defaults/assets/logos/partnerlogos/index"

const PartnerBanner = styled.div`
    background: ${color.main_light};
    border-radius: 5px;
`

const SVGcontainers = styled.div`
  height: 3.3rem;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  background-color: ${color.accent_light};
  padding: 12px 14px;
  border-radius: 5px;
  svg {
    fill: ${color.main_dark};
    height: 100%;
    max-width: 90px;
  }
  p {
    line-height: 1.8rem;
  }
`



const PartnerSection = () => {
    const founders = [Daimler, Pnp, Arena2036, Unistuttgart];
    const anchor = [Dxc, Zfwabco, Basf, Porsche, Dpdhl, Webasto, Rrps, Motherson, Tsystems, Bosch];
    const ecosystem = [Murata, Agc, Linde, Jardines, Wieland, Covea, Bp, Tuev, Hyundai, Yanfeng, Bleistahl, Eberspaecher, Posteitaliane, Faurecia, Sekisui, Adac ]
  
  return (
    <section css={css`
        background: white;
        /* position: relative; */
        /* &:before {
            content: '';
            width: 100%;
            height: 25%;
            background: ${color.main_dark};
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        } */
    `}>
      <PartnerBanner css={[wrapper, css`
          padding: 25px 0;
          z-index: 1;
          position: relative;
          margin-top: -1em;
      `]}>
        <Marquee velocity={15}>
          <SVGcontainers
            css={css`
              background: none;
            `}
          >
            <p
              css={[
                pretitle,
                css`
                  color: ${color.main_dark};
                  margin-left: 35px;
                `,
              ]}
            >
              Founded by
            </p>
          </SVGcontainers>
          {founders.map((founder, index) => (
            <SVGcontainers key={index}>{founder()}</SVGcontainers>
          ))}

          <SVGcontainers
            css={css`
              background: none;
            `}
          >
            <p
              css={[
                pretitle,
                css`
                  color: ${color.main_dark};
                  margin-left: 35px;
                `,
              ]}
            >
              Anchor Partners
            </p>
          </SVGcontainers>
          {anchor.map((founder, index) => (
            <SVGcontainers key={index}>{founder()}</SVGcontainers>
          ))}

          <SVGcontainers
            css={css`
              background: none;
            `}
          >
            <p
              css={[
                pretitle,
                css`
                  color: ${color.main_dark};
                  margin-left: 35px;
                `,
              ]}
            >
              Ecosystem Partners
            </p>
          </SVGcontainers>
          {ecosystem.map((founder, index) => (
            <SVGcontainers key={index}>{founder()}</SVGcontainers>
          ))}
        </Marquee>
      </PartnerBanner>
    </section>
  )
}

export default PartnerSection;