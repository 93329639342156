import React from "react"
import { css } from "@emotion/core"
import { color } from "../../components/defaults/styles/theme"
import BackgroundImage from "gatsby-background-image-es5"
import mq from '../defaults/styles/mediaquerys';
import { Default } from "../defaults/assets/svg/mobilenav"
import { live, blink } from "../defaults/header/navigation"
import Link from "../link"

const HeroSection = ({ data }) => {
    
    return (
      <>
        <div
          css={css`
            width: 100%;
            height: calc(100vh - 10em);
            background: ${color.main_dark};
            color: white;
            display: flex;
            align-items: center;
            position: relative;
          `}
        >
          <BackgroundImage
            fluid={data.file.childImageSharp.fluid}
            css={css`
              width: 100%;
              height: 100%;
              display: flex;
              align-items: flex-end;
              justify-content: center;
            `}
          >
            <div
              css={css`
                text-align: center;
                margin-bottom: 2em;
              `}
            >
              <h1
                css={css`
                  margin: 0;
                  font-size: 1.45em;
                  margin-bottom: 0.25em;
                  ${mq[0]} {
                    font-size: 2em;
                  }
                  ${mq[1]} {
                    font-size: 2.35em;
                  }
                  ${mq[2]} {
                    font-size: 2.85em;
                  }
                `}
              >
                The sum of innovation is infinite.
              </h1>
              <p
                css={css`
                  font-size: 0.75em;
                  ${mq[0]} {
                    font-size: 1em;
                  }
                  ${mq[1]} {
                    font-size: 1.15em;
                  }
                `}
              >
                STARTUP AUTOBAHN EXPO 8 — September 2020
              </p>
              
              {live ? (
                <div
                  css={css`
                    margin-bottom: 1em;
                    display: inline-block;
                  `}
                >
                  <Link
                    to="/live/one"
                    css={css`
                      border: none;
                      background: none;
                      width: 100%;
                      text-decoration: none;
                      font-size: 1.2rem;
                      font-weight: 500;
                      position: relative;
                      padding: 0.675rem 1.5rem;
                      display: flex;
                      align-items: center;
                      color: white;
                      font-weight: 700;
                      letter-spacing: 0.03em;
                      &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        border-radius: 5px;
                        background: ${color.rec};
                        opacity: 1;
                        z-index: -1;
                      }
                      svg {
                        height: 0.9em;
                        width: auto;
                        margin-right: 0.5rem;
                        fill: white;
                        stroke: white;
                        animation: ${blink} 2s ease-in-out infinite;
                      }
                    `}
                  >
                    <Default />
                    We are live. Join Now!
                  </Link>
                </div>
              ) : (
                ""
              )}

            </div>
          </BackgroundImage>
        </div>
      </>
    )
}

export default HeroSection;