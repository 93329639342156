import React from "react"
import { color } from "../defaults/styles/theme";
import { wrapper } from "../defaults/styles/elements";
import { css } from "@emotion/core";
import mq from "../defaults/styles/mediaquerys";


const OverviewIntro = () => {
    return (
      <section
        css={css`
          background: white;
          width: 100%;
          padding: 12em 0 4em;
        `}
      >
        <div
          css={[
            wrapper,
            css`
              padding: 0 5em 0 2em;
              ${mq[2]} {
                padding: 0 2em;
                display: flex;
                justify-content: space-between;
              }
            `,
          ]}
        >
          <div>
            <h2
              css={css`
                margin-bottom: 1rem;
                font-weight: 700;
                font-size: 3rem;
                color: ${color.main_dark};
                line-height: 1.2em;
                text-indent: -.12em;
                letter-spacing: -0.02em;
                ${mq[1]} {
                  font-size: 4rem;
                }
              `}
            >
              The Virtual Program
            </h2>
            <p
              css={css`
                margin: 0;
                font-size: 1rem;
                line-height: 1.2em;
                color: ${color.main_dark};
                ${mq[1]} {
                  font-size: 1.5rem;
                }
              `}
            >
              A sneak peak about what will happen *
            </p>
          </div>
          <div
            css={css`
              flex: 0 1 15rem;
            `}
          >
            <p
              css={css`
                font-size: 0.75em;
                margin: 0;
                color: ${color.main_dark};
                padding-top: 0.8rem;
                max-width: 15rem;
                line-height: 1.4em;
              `}
            >
              * further details, schedules, speaker and additional information can be found in the subsections of this webpage
            </p>
          </div>
        </div>
      </section>
    )
}

export default OverviewIntro;