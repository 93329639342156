import React from 'react';
import { css } from '@emotion/core';
import { wrapper } from '../defaults/styles/elements';
import mq from '../defaults/styles/mediaquerys';
import { color } from '../defaults/styles/theme';
import Link from '../link';


const numbers_data = [
  {
    "number": "5.1k",
    "title": "Event Attendees in total",
    "url": "/people/all"
  },
  {
    "number": "9h",
    "title": "content on 2 days",
    "url": "/agenda/day1"
  },
  {
    "number": "150",
    "title": "speakers and session hosts",
    "url": ""
  },
  {
    "number": "75",
    "title": "countries represented",
    "url": ""
  },
]


const NumbersEl = ({data}) => {
  const elStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
  
  return (
    <Link
      to={data.url}
      css={css`
        text-decoration: none;
        color: ${color.main_dark};
        background: ${color.accent_light};
        border-radius: 5px;
        flex: 0 0 100%;
        margin: 0.25em 0;
        display: flex;
        flex-direction: column;
        max-width: 17rem;
        ${mq[1]} {
          flex: 0 0 calc(100% / 3 - 0.5em);
        }
      `}
    >
      <div
        css={[
          elStyle,
          css`
            padding: 2em 2em 1em 2em;
          `,
        ]}
      >
        <p
          css={css`
            margin: 0;
            font-size: 3em;
            font-weight: 800;
            letter-spacing: 0.05em;
            line-height: 1em;
          `}
        >
          {data.number}
        </p>
      </div>
      <div
        css={[
          elStyle,
          css`
            flex: 0 0 2.5em;
            position: relative;
            /* &:before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              height: 1px;
              background: ${color.main_dark};
              opacity: 0.2;
              width: 100%;
            } */
          `,
        ]}
      >
        <p
          css={css`
            margin: 0 0 2em 0;
            font-size: 0.75em;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: 0.05em;
          `}
        >
          {data.title}
        </p>
      </div>
    </Link>
  )
}


const Numbers = () => {
  return (
    <section
      css={css`
        background: white;
        width: 100%;
      `}
    >
      <div css={wrapper}>
        <div
          css={[
            css`
              border-radius: 5px;
              margin-top: -2em;
              background: ${color.main_light};
              padding: 1.5em 2em;
              width: 100%;
              z-index: 1;
              position: relative;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-evenly;
            `,
          ]}
        >
          <p
            css={[
              css`
                position: absolute;
                bottom: calc(100% + 0.5em);
                right: 1.5em;
                margin: 0;
                font-size: 0.6em;
                font-weight: 800;
                letter-spacing: 0.07em;
                text-transform: uppercase;
              `,
            ]}
          >
            July 16, 2019 – EXPO Day 6
          </p>

          {numbers_data.map((el, index) => (
            <NumbersEl data={el} key={index} />
          ))}
        </div>

        <div
          css={[
            css`
              padding: 1em;
              width: 100%;
              position: relative;
              text-align: center;
              &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                height: 1px;
                background: ${color.main_dark};
                opacity: 0.2;
                width: 100%;
                transform: translateY(-50%);
              }
            `,
          ]}
        >
          <p
            css={css`
              margin: 0;
              font-weight: 700;
              letter-spacing: 0.05em;
              text-transform: uppercase;
              background: white;
              position: relative;
              display: inline;
              padding: 0 1.5em;
            `}
          >
            EXPO 8 – Facts and Figures
          </p>
        </div>
      </div>
    </section>
  )
}

export default Numbers;