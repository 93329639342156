import React from "react"
import { css } from "@emotion/core"
import { wrapper, ArrowLink } from "../../components/defaults/styles/elements"
import { color } from "../../components/defaults/styles/theme"
import Sun from "../defaults/assets/svg/sun.svg";
import Sunrise from "../defaults/assets/svg/sunrise.svg";
import mq from "../defaults/styles/mediaquerys"

// currently not in use – logos have been removed
const Icon = {
    noon: Sun,
    afternoon: Sunrise,
}

const data = [
    {
        title: 'Day One',
        number: '23',
        time: '04:00 PM – 06:00 PM CEST',
        content: [
            {
                header: 'Thought-Leader Voices',
                text: 'Top-level experts, decision-makers and visionaries from our partner ecosystem share their thoughts and insights.'
            },
            {
                header: 'Project Highlights',
                text: 'We give the stage to the most successful, innovative and game-changing startups and business units of our current Program 8.'
            },
            {
                header: 'Behind the Platform',
                text: 'STARTUP AUTOBAHN is not like any other innovation platform. We share secrets and best practices from Europe’s biggest Open Innovation Platform.'
            },
        ]
    },
    {
        title: 'Day Two',
        number: '24',
        time: '12:00 PM - 07:00 PM CEST',
        content: [
            {
                header: 'Tech Deep Dive',
                text: 'We will tell the stories of the 30+ successful pilot projects and PoCs to an invite-only audience and dive into the central developments in Mobility, Production, Enterprise 2.0, Sustainability and beyond.'
            },
            {
                header: 'Breakout Sessions',
                text: 'Startups, Partners, VCs and attendees meet in tech- and interest-specific breakout rooms in small discussion groups for deep exchange or workshops.'
            },
            {
                header: 'Virtual Discovery Area',
                text: 'Selected attendees are given access to our virtual discovery area where startups, business units and pilot projects are exclusively showcased in full detail.'
            },
        ]
    },
    {
        title: 'Day Two',
        number: '24',
        time: '12:00 PM – 07:00 PM CEST',
        content: [
            {
                header: 'Inspirational Keynotes',
                text: 'We give the stage the best of STARTUP AUTOBAHN’s extensive network of industry-leading companies, disrupting startups and world-class VCs.'
            },
            {
                header: 'The Element of Surprise',
                text: 'We never fail to surprise our audience: we have brought to you a walking giant and an astronaut – only we know what’s next.'
            },
            {
                header: 'Behind the Scenes',
                text: 'We take a tour behind the scenes of STARTUP AUTOBAHN’s first ever virtual EXPO and give a recap of our planning process.'
            },
        ]
    },
]

const styleTitle = css`
  color: white;
  font-weight: 700;
  margin: 0;
  line-height: 1em;
`

const styleBackground = css`
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  &:after {
    background-color: ${color.main_light};
    /* opacity: 0.08; */
    height: 100%;
    z-index: 0;
  }
  &:before {
    height: 1rem;
    background-color: white;
    z-index: 1;
  }
`

const styleInner = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${mq[2]} {
    flex-direction: row;
    align-items: stretch;
  }
`

const RoundCard = ({children, index}) => {
    
    return (
      <div
        css={css`
          background: ${color.main_dark};
          padding: ${index === 1 ? "2em 2em 0 2em" : index === 2 ? "0 2em 2em 2em" : "2em"};
          border-radius: ${index === 0 ? "5px" : index === 1 ? "5px 5px 0 0" : index === 2 ? "0 0 5px 5px" : ""};
          margin-bottom: ${index !== 1 ? "2rem" : "none"};
          max-width: 35rem;
          z-index: 1;
          &:last-of-type {
            margin-bottom: 0;
          }
          ${mq[2]} {
            padding: 2em;
            max-width: unset;
            margin-bottom: 0;
            flex: 1.4123 0 30.34%;
            margin-right: ${index === 0 ? "2rem" : "0"};
            border-radius: ${index === 0
              ? "5px 5px 5px 5px"
              : index === 1
              ? "5px 0 0 5px"
              : "0 5px 5px 0"};
          }
        `}
      >
        {children}
      </div>
    )
}


const RoundCard__Title = ({className, data}) => {
    return (
      <>
        <p className={className}
          css={[
            styleTitle,
            css`
              padding-left: 0.5em;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `,
          ]}
        >
          {data}
        </p>
      </>
    )
}

const RoundCard__Date = ({data}) => {
    return (
      <div
        css={css`
          background: white;
          border-radius: 5px;
          padding: 0.5em 0.35rem;
          display: inline-box;
        `}
      >
        <p
          css={[
            styleTitle,
            css`
              color: ${color.main_dark};
            `,
          ]}
        >
          {data}
        </p>
      </div>
    )
}

const RoundedCard__Time = ({className, data, icon, index}) => {
    // icons have been removed
    // let Weathericon = icon[data];
    return (
      <div
        className={className}
        css={css`
          display: flex;
          align-items: center;
          min-width: 0;
          svg {
            fill: white;
            height: 1.3rem;
          }
        `}
      >
        {/* icons have been removed */}
        {/* <Weathericon /> */}
        <p
          css={[
            styleTitle,
            css`
              font-weight: 400;
              font-size: 0.75em;
              padding-left: ${index === 0 ? "2em" : "0.5em"};
              letter-spacing: 0.02em;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            `,
          ]}
        >
          {data}
        </p>
      </div>
    )
}

const RoundedCard__Item = ({data}) => {
    return(
        <div css={css`
            margin-top: 2rem;
            max-width: 95%;
        `}>
            <p css={[styleTitle, css`
                font-size: .9rem;                
            `]}>{data.header}</p>
            <p css={css`
                color: white;
                padding-top: .5rem;
                font-weight: 400;
                font-size: .75rem;
            `}>
                {data.text}
            </p>
        </div>
    )
}

const CardElement = ({data, icon}) => {
    return (
      <>
        <div css={[wrapper, styleInner]}>
          {data.map((item, index) => (
            <RoundCard key={index} index={index}>
              <div
                css={css`
                  display: ${index !== 2 ? "flex" : "none"};
                  justify-content: space-between;
                  align-items: center;
                  ${mq[2]} {
                    display: flex;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    visibility: ${index !== 2 ? "visible" : "hidden"};
                  `}
                >
                  <RoundCard__Date data={item.number} />
                  <RoundCard__Title
                    data={item.title}
                    css={css`
                      ${mq[2]} {
                        display: ${index !== 2 ? "inline" : "none"};
                      }
                    `}
                  />
                </div>
                <RoundedCard__Time
                  data={item.time}
                  icon={icon}
                  index={index}
                  css={css`
                    visibility: ${index !== 2 ? "visible" : "hidden"};
                    ${mq[2]} {
                      visibility: visible;
                      display: ${index !== 1 ? "flex" : "none"};
                    }
                  `}
                />
              </div>
              {item.content.map((el, index) => (
                <RoundedCard__Item key={index} data={el} />
              ))}
            </RoundCard>
          ))}
        </div>
      </>
    )
}


const Overview = () => {

    return (
      <>
        <section
          css={[
            styleBackground,
            css`
              position: relative;
              background: white;
              padding-bottom: 2rem;
              /* padding-bottom: 2rem; */
            `,
          ]}
        >
          <CardElement data={data} icon={Icon} />
        </section>
        <div
          css={css`
            background: ${color.main_light};
            padding: 0 0 2em;
          `}
        >
          <ArrowLink
            url="/agenda/day1"
            text="More about the agenda"
            css={[
              wrapper,
              css`
                background: ${color.main_light};
                color: ${color.main_dark};
                padding: 0 2em;
                svg {
                  stroke: ${color.main_dark};
                }
              `,
            ]}
          />
        </div>
      </>
    )
}


export default Overview;