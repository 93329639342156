import React from "react"
import { color } from "../defaults/styles/theme";
import { wrapper, ArrowLink } from "../defaults/styles/elements";
import { css } from "@emotion/core";
import mq from "../defaults/styles/mediaquerys";



const dates = [
  {
    weekday: 'Wednesday',
    date: 'September 23rd',
  },
  {
    weekday: 'Thursday',
    date: 'September 24th',
  }
]

const content = "<p>EXPO 8 welcomes the brightest minds and top innovators to showcase their technologies to industry leaders and curious creators around the world.</p><p>Join us for two days of innovation, technology, and discovery.</p>"



const Teaser = () => {
    return (
      <section
        css={css`
          padding: 8em 0;
          background: white;
          color: ${color.main_dark};
        `}
      >
        <div
          css={[
            wrapper,
            css`
              display: flex;
              max-width: 35rem;
              flex-direction: column;
              padding: 0 1.5em 0 3em;
              ${mq[1]} {
                max-width: 45rem;
                padding: 3em;
              }
              ${mq[2]} {
                padding-left: 5em;
                max-width: 55rem;
              }
            `,
          ]}
        >
          <div css={css``}>
            {dates.map((date, index) => (
              <h2
                key={index}
                css={css`
                  text-transform: uppercase;
                  font-weight: 900;
                  font-size: 1.5em;
                  margin: 0;
                  letter-spacing: .02em;
                  :nth-of-type(2) {
                    padding-left: 1.5em;
                  }
                  ${mq[1]} {
                    font-size: 2em;
                  }
                  ${mq[2]} {
                    font-size: 3em;
                  }
                `}
              >
                {date.weekday.substring(0, 3) + " " + date.date}
              </h2>
            ))}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            css={css`
              margin-top: 0.75em;
              ${mq[0]} {
                margin-top: 1em;
              }
              ${mq[1]} {
                margin-top: 2em;
              }
              p {
                font-size: 1em;
                font-weight: 400;
                line-height: 1.6em;
                margin-bottom: 0.5em;
                ${mq[1]} {
                  font-size: 1.3em;
                }
              }
            `}
          />
          <ArrowLink
            text="More about STARTUP AUTOBAHN"
            url="https://startup-autobahn.com"
            css={css`
              color: ${color.main_dark};
              margin-top: 1em;
              svg {
                stroke: ${color.main_dark};
              }
            `}
          />
          <ArrowLink
            text="Or Take a look at EXPO 8 Rearview"
            url="/rearview"
            css={css`
              color: ${color.main_dark};
              margin-top: .5em;
              svg {
                stroke: ${color.main_dark};
              }
            `}
          />
        </div>
      </section>
    )
}

export default Teaser;