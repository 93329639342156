import React from 'react';
import { css } from "@emotion/core"
import { wrapper } from "../defaults/styles/elements"
import mq from "../defaults/styles/mediaquerys"
import { color } from "../defaults/styles/theme"
import Link from "../link"
import AutomotiveIT from '../defaults/assets/logos/mediapartners/amIT.svg';
import Ellectric from '../defaults/assets/logos/mediapartners/ellectric.svg';
import OneENine from '../defaults/assets/logos/mediapartners/OneENine.svg';
import TechnologyReview from '../defaults/assets/logos/mediapartners/technologyreview.svg';


const MediaPartnerData = [
  {
    name: "1E9",
    url: "https://1e9.community/",
    icon: OneENine,
  },
  {
    name: "automotiveIT",
    url: "https://www.automotiveit.eu/",
    icon: AutomotiveIT,
  },
  {
    name: "ellectric",
    url: "https://www.weareellectric.com/",
    icon: Ellectric,
  },
  {
    name: "Technology Review",
    url: "https://www.heise.de/tr/",
    icon: TechnologyReview,
  },
  {
    name: "WhichEV",
    url: "https://www.whichev.net/",
  },
]

const MediaPartnerEl = ({data, placeholder}) => {
  if (placeholder) {
    return (
      <div
        css={css`
          flex: 0 0 calc(100% / 2 - 0.5em);
          ${mq[1]} {
            flex: 0 0 calc(100% / 3 - 0.5em);
          }
          ${mq[2]} {
            flex: 0 0 calc(100% / 5 - 0.5em);
          }
        `}
      ></div>
    )
  }
  
  const elStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
  `
  let Icon = undefined;
  if (data.icon !== undefined) {
    Icon = data.icon
  }

  return (
    <Link
      to={data ? data.url : ""}
      css={css`
        text-decoration: none;
        color: ${color.main_dark};
        background: white;
        border-radius: 5px;
        display: flex;
        position: relative;
        flex-direction: column;
        margin: .5em 0;
        flex: 0 0 calc(100% / 2 - 0.5em);
        ${mq[1]} {
          flex: 0 0 calc(100% / 3 - 0.5em);
          max-width: unset;
        }
        ${mq[2]} {
          flex: 0 0 calc(100% / 5 - 0.5em);
          max-width: unset;
        }
        &:after {
          content: "";
          background: grey;
          position: absolute;
          width: calc(100% - 2px);
          top: 100%;
          left: 50%;
          height: 1px;
          transform: translateX(-50%);
          opacity: 0.5;
        }
      `}
    >
      <div
        css={[
          elStyle,
          css`
            padding: 2em 1.5rem;
            margin: 0;
            line-height: 1em;
            height: 8rem;
            background: ${color.main_dark};
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            ${mq[0]} {
              height: 7rem;
              padding: 1.5em 4rem;
            }
            ${mq[1]} {
              height: 9rem;
              padding: 2em 2rem;
            }
            ${mq[2]} {
              padding: 2em 3rem;
            }
            svg {
              width: 100%;
              height: 100%;
              fill: ${color.main_dark};
              fill: white;
            }
          `,
        ]}
      >
        {data.icon !== undefined ? <Icon /> : ""}
      </div>
      <div
        css={[
          elStyle,
          css`
            flex: 0 0 3em;
            position: relative;
          `,
        ]}
      >
        <p
          css={css`
            margin: 0;
            font-size: 0.75em;
            font-weight: 600;
            letter-spacing: 0.03em;
          `}
        >
          {data.name}
        </p>
      </div>
    </Link>
  )
}

const MediaPartner = () => {
  return (
    <section
      css={css`
        background: ${color.main_light};
        padding-bottom: 5em;
        padding-top: 5em;
      `}
    >
      <div css={[wrapper, css``]}>
        <div
          css={css`
            position: relative;
            margin-bottom: 1em;
            &:before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              background: ${color.main_dark};
              width: 100%;
              height: 1px;
              opacity: 0.28;
            }
          `}
        >
          <h2
            css={css`
              background: ${color.main_light};
              z-index: 1;
              position: relative;
              display: inline;
              padding: 0 1rem 0 0;
              font-size: 0.85em;
              text-transform: uppercase;
              font-weight: 700;
              letter-spacing: 0.075em;
            `}
          >
            Media Partners of EXPO8
          </h2>
        </div>
        <div
          css={[
            css`
              width: 100%;
              z-index: 1;
              position: relative;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            `,
          ]}
        >
          {MediaPartnerData.map((el, index) => (
            <MediaPartnerEl data={el} key={index} />
          ))}
          <MediaPartnerEl placeholder />
          <MediaPartnerEl placeholder />
          <MediaPartnerEl placeholder />
          <MediaPartnerEl placeholder />
        </div>
      </div>
    </section>
  )
}

export default MediaPartner;