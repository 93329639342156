import React from "react";
import { Global, css } from "@emotion/core"
import "typeface-inter"
import Layout from "../components/layout"
import { color } from "../components/defaults/styles/theme"
import HeroSection from "../components/main/hero"
import Overview from "../components/main/overview"
import PartnerSection from "../components/main/partnersection"
import Teaser from "../components/main/teaser"
import About from "../components/main/about"
import Numbers from "../components/main/numbers"
// import RegisterNow from "../components/registernow";
import { graphql } from "gatsby"
import OverviewIntro from "../components/main/overview_intro";
import MediaPartners from '../components/main/mediapartner';
import ThanksHDM from '../components/main/thankshdm';
import PressRelease from '../components/main/pressrelease';
import PressFooter from '../components/pressfooter';

const Index = ({ data }) => {
  return (
    <>
      <Global
          styles={css`
              body {
                  background: ${color.main_dark}
              }
          `}
      />
      <Layout dark>
        <HeroSection data={ data } />
        <PartnerSection />
        <Teaser />
        <About data={data.expobackground} />
        <Numbers />
        <OverviewIntro />
        <Overview />
        <PressRelease />
        <MediaPartners />
        <ThanksHDM />
        <PressFooter theme={ color.main_light } />
        {/* <RegisterNow theme={ color.main_light } /> */}
      </ Layout>
    </>
  )
}

export const query = graphql`
         query {
           file(relativePath: { eq: "20200812-reach.png" }) {
             childImageSharp {
               fluid (
                 maxWidth: 1900,
                 quality: 60,
                 ) {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
           expobackground: file(relativePath: { eq: "LRG_DSC03609.jpg" }) {
             childImageSharp {
               fluid (
                 grayscale: true,
                 maxWidth: 1900,
                 quality: 60
                 ) {
                 ...GatsbyImageSharpFluid_withWebp
               }
             }
           }
         }
       `

export default Index;