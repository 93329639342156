import React from "react"
import { color } from "../defaults/styles/theme";
import { wrapper } from "../defaults/styles/elements";
import { css } from "@emotion/core";
import mq from "../defaults/styles/mediaquerys";
import { ArrowLink } from "../defaults/styles/elements"
import Info from "../defaults/assets/svg/info.svg";
import BackgroundImage from 'gatsby-background-image-es5';


const About = ({data}) => {
    return (
      <>
        <BackgroundImage
          fluid={data.childImageSharp.fluid}
          css={css`
            position: relative;
            display: flex;
            background-position: center;
            background-size: cover;
            width: 100%;
            height: 100%;
            padding: 0 0 8em;
          `}
        >
          <div
            css={css`
              width: 100%;
              height: 2em;
              top: 0;
              left: 0;
              position: absolute;
              background: white;
            `}
          ></div>
          <div
            css={[
              wrapper,
              css`
                width: 100%;
                position: relative;
              `,
            ]}
          >
            <div
              css={css`
                display: flex;
                justify-content: center;
              `}
            >
              <div
                css={css`
                  max-width: 35em;
                  padding: 2rem;
                  border-radius: 5px;
                  background: ${color.main_dark};
                  ${mq[1]} {
                    transform: translateX(10%);
                  }
                  ${mq[2]} {
                    transform: translateX(25%);
                  }
                  h2 {
                    margin: 0;
                    font-size: 1rem;
                    font-weight: 700;
                    color: white;
                    line-height: 1em;
                  }
                  p {
                    margin-bottom: 0.9em;
                    line-height: 1.9em;
                    font-size: 0.85rem;
                    color: white;
                  }
                `}
              >
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    margin-bottom: 2rem;
                  `}
                >
                  <div
                    css={css`
                      padding: 0.5em 0.35rem;
                      background: white;
                      border-radius: 5px;
                      line-height: 1em;
                      margin-right: 0.5em;
                      svg {
                        height: 1rem;
                        width: auto;
                        line-height: 1em;
                      }
                    `}
                  >
                    <Info />
                  </div>
                  <h2>What is EXPO 8?</h2>
                </div>
                <p>
                  It is a 2-day virtual event, showcasing deep tech solutions in
                  mobility, production, enterprise, sustainability, innovation,
                  and beyond. Guided by the motto “the sum of innovation is
                  infinite”, all attendees will engage with over 30 pilots,
                  projects, and implementations and be inspired and entertained
                  by a lineup of industry experts and thought leaders from
                  around the world.
                </p>
                <p>
                  Those with all-access passes will gain entry to 1:1 networking
                  opportunities through a custom event app and virtual platform;
                  participate in limited-entry breakout sessions hosted by
                  startups, partners, and special guests; and can unlock
                  additional content and materials on our website.
                </p>
                <p>
                  This EXPO you can expect high quality content in a
                  user-friendly format. The spotlight will be on the impressive
                  projects that have been created between startups and our
                  partners over the last 100 days. This is after all, the core
                  of what we do at STARTUP AUTOBAHN powered by Plug and Play.
                </p>
                <ArrowLink
                  url="https://startup-autobahn.com"
                  text="More about STARTUP AUTOBAHN"
                  css={css`
                    margin-top: 1.2em;
                  `}
                />
              </div>
            </div>
          </div>
        </BackgroundImage>
      </>
    )
}

export default About;